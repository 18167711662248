import Button from 'Components/Button';
import MediaService from 'Services/Http/Media.service';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Descargable = (props) => {
  const [loader, setLoader] = useState(false);
  const [label, setLabel] = useState();
  const { id, file = '' } = props;
  const openFile = (data) => {
    let w = window.open(
      data,
      '_blank',
      'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no'
    );
    w.opener = null;
  };
  useEffect(() => {
    setLabel(capitalizeFirstLetter(file));
  }, []);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const descargable = async () => {
    setLoader(true);
    try {
      const response = await MediaService.getLink(id, file);
      openFile(response.data);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  return (
    <>
      <Button
        label={label}
        iconprefix={['fa', 'download']}
        onClick={() => descargable()}
        loading={loader}
      />
    </>
  );
};

Descargable.propTypes = {
  id: PropTypes.string,
  typeMime: PropTypes.bool,
  typeFile: PropTypes.string,
  file: PropTypes.string
};

export default Descargable;
