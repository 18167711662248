import Button from 'Components/Button';
import MediaService from 'Services/Http/Media.service';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Label from 'Components/Label';
import ModalComponent from 'Components/Modal';

const ImageComponent = (props) => {
  const {
    icon = ['fa', 'images'],
    label = '',
    idPersona,
    idExpediente,
    typePerson,
    name,
    deleteItem = false
  } = props;
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [disabledFile, setDisabledFile] = useState(true);
  const [loader, setLoader] = useState(false);
  const [document, setDocument] = useState({});
  const [stateModal, setStateModal] = useState(false);

  const inputFile = useRef(null);
  const onFileChange = (event) => {
    setSelectedFiles(event.target.files[0]);
    setDisabledFile(false);
  };

  useEffect(() => {
    getImage();
  }, []);

  const getImage = async () => {
    try {
      const response = await MediaService.getDocumento(idExpediente, idPersona, name);
      setDocument(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const openFile = async () => {
    const url = 'data:' + document.mime + ';base64,' + document.base64;
    let w = window.open('', '_blank');
    if (document.mime.includes('image')) {
      let image = new Image();
      image.src = url;
      w.document.write(image.outerHTML);
      w.document.close();
    }
    if (document.mime.includes('pdf')) {
      w.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
      w.document.close();
    }
  };
  const onFileUpload = async () => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append('images', selectedFiles, selectedFiles.name);
      formData.append('idPersona', idPersona);
      formData.append('idExpediente', idExpediente);
      formData.append('typePerson', typePerson);
      formData.append('name', name);
      await MediaService.documentos(formData);
      await getImage();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  const changeState = (state) => {
    setStateModal(state);
  };
  const deleteFile = async () => {
    try {
      await MediaService.deleteDocumento(idExpediente, idPersona, name);
      changeState(false);
      getImage();
    } catch (e) {
      console.log(e);
      changeState(false);
      getImage();
    }
  };
  return (
    <>
      <Label text={label} />
      <ModalComponent
        show={stateModal}
        changeState={changeState}
        typeModal={'decision'}
        title={'Se eliminara el archivo'}
        stateModal={'warning'}
        actionOK={deleteFile}
      />
      {Object.keys(document).length == 0 ? (
        <>
          <input type="file" ref={inputFile} onChange={onFileChange} hidden />
          <Button iconprefix={icon} onClick={() => inputFile.current.click()} />
          <Button label="Subir" onClick={onFileUpload} disabled={disabledFile} loading={loader} />
        </>
      ) : (
        <>
          <Button label="ver" iconprefix={['fa', 'eye']} onClick={() => openFile()} />
          {deleteItem ? (
            <Button
              color="error"
              iconprefix={['fa', 'trash']}
              onClick={() => setStateModal(true)}
            />
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

ImageComponent.propTypes = {
  idExpediente: PropTypes.number.isRequired,
  idPersona: PropTypes.number.isRequired,
  icon: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  typePerson: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  deleteItem: PropTypes.bool
};

export default ImageComponent;
