import Label from 'Components/Label';
import CreditoService from 'Services/Http/Credito.service';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Documentos from './Documentos';
import PropTypes from 'prop-types';
import SolicitarAutorizacion from './Solicitar-Autorizacion';
import Autorizacion from './Autorizar';
import Credito from './Credito';
import Card from 'Components/Card';
import PhoneService from 'Services/Http/Phone.service';
import ClientesService from 'Services/Http/Clientes.service';

const Verificacion = (props) => {
  const {
    validacion = false,
    autorizacion = false,
    validacionCredito = false,
    title = '',
    editable = false
  } = props;
  const { id } = useParams();
  const [idPersona, setIdPersona] = useState('');
  const [dataExpediente, setDataExpediente] = useState({});
  const [telefonoCliente, setTelefonoCliente] = useState({});
  const [telefonoAval, setTelefonoAval] = useState({});
  const [location, setLocation] = useState(null);
  const idProspecto = useSelector((state) => state.prospecto?.id);
  const idExpediente = useSelector((state) => state.expediente?.expediente?.id);
  const navigate = useNavigate();
  useEffect(() => {
    orderData();
  }, [id, idExpediente]);
  useEffect(() => {
    if (Object.keys(dataExpediente).length > 0) {
      getPhoneClient();
      getPhoneAval();
    }
  }, [dataExpediente]);
  const orderData = async () => {
    try {
      if (id in idProspecto) {
        await setIdPersona(idProspecto[id]);
        console.log(idExpediente);
        const response = await CreditoService.expediente(idProspecto[id], idExpediente);
        const { latitude, longitude } = response.data;
        if (latitude && longitude) {
          setLocation({ latitude, longitude });
        }
        setDataExpediente(response.data);
      } else {
        navigate('/main');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getPhoneClient = async () => {
    try {
      const response = await PhoneService.getPhonesById(
        dataExpediente.idTelefonos,
        dataExpediente.idPersona
      );
      console.log(response);
      const data = response.data.map((item) => {
        return {
          idOrigin: item.id,
          movil: item.telefono,
          type: 'cliente',
          valido: item.valido,
          tipoTelefono: item.descripcionTipoTelefono
        };
      });
      setTelefonoCliente(data);
    } catch (e) {
      console.log(e);
    }
  };
  const getPhoneAval = async () => {
    try {
      const response = await ClientesService.getEndorsementById(dataExpediente.idAval);
      const data = response.data;
      const phone = {
        idOrigin: data.idOrigin,
        movil: data.movil,
        type: 'aval',
        valido: data.valido,
        tipoTelefono: 'Celular'
      };
      setTelefonoAval([phone]);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Label text={title} id={idPersona} />
      <div className="">
        {Object.keys(dataExpediente).length > 0 ? (
          <>
            {validacion ? (
              <>
                <SolicitarAutorizacion
                  idExpediente={dataExpediente.id}
                  idPersona={dataExpediente.idPersona}
                />
              </>
            ) : (
              ''
            )}
            {validacionCredito ? <Credito expedienteData={dataExpediente} /> : ''}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 my-3">
              <Documentos
                title="Solicitante"
                idDomicilio={dataExpediente.idDomicilio}
                idPersona={dataExpediente.idPersona}
                idExpediente={dataExpediente.id}
                expediente={dataExpediente}
                typePerson="cliente"
                location={location}
                cliente={true}
                editable={editable}
                phone={telefonoCliente}
              />
              <Documentos
                title="Aval"
                idDomicilio={dataExpediente.idAvalDomicilio}
                idPersona={dataExpediente.idPersonaAval}
                expediente={dataExpediente}
                typePerson="aval"
                editable={editable}
                phone={telefonoAval}
              />
              {autorizacion ? (
                <>
                  {dataExpediente.observaciones ? (
                    <Card title="Observaciones">
                      <div className="col-span-1 lg:col-span-2 my-3">
                        <div>
                          <div>Observaciones: </div>
                          <div className="font-semibold">{dataExpediente.observaciones}</div>
                        </div>
                      </div>
                    </Card>
                  ) : (
                    ''
                  )}
                  <Autorizacion
                    idExpediente={dataExpediente.id}
                    idPersona={dataExpediente.idPersona}
                    idCredito={dataExpediente.idCredito}
                    expediente={dataExpediente}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

Verificacion.propTypes = {
  validacion: PropTypes.bool,
  autorizacion: PropTypes.bool,
  validacionCredito: PropTypes.bool,
  title: PropTypes.string,
  editable: PropTypes.bool
};

export default Verificacion;
